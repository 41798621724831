
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "../components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MsAddModal from "@/components/staff/MsAddModal.vue";
import MsEditModal from "@/components/staff/MsEditModal.vue";
import { Classroom } from "@/entities/classroom";
import { School } from "@/entities/school";
import { TutorMeta } from "@/api/tutor";
import { Getter, State } from "@/store/helper";
import { convertToTutorTableData, tutorsTableDefinition } from "@/utils/table";
import { User } from "@/entities/user";
import { getAllLoginTutors } from "@/api/user";
import { getClassRoomsBasedOnRole } from "@/api/classroom";

@Options({
  components: {
    MButton,
    MIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MsAddModal,
    MsEditModal
  },
  watch: {
    accessibleClassRooms: function() {
      this.updateDatas();
    },
    tutors: function() {
      this.updateDatas();
    }
  }
})
export default class StaffList extends Vue {
  datas: { [key: string]: string | number | boolean }[] = [];
  definitions = tutorsTableDefinition;
  searchKeyword = "";
  selectedId = "";
  isDisplayedAddTutorModal = false;
  isDisplayedEditTutorModal = false;
  tutorUsers: User[] = [];

  @State("classrooms") classrooms!: Classroom[];
  @State("school") school?: School;
  @State("tutor") tutor!: TutorMeta | null;
  @State("tutors") tutors!: TutorMeta[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isServiceProvider") isServicePrivider!: boolean;

  get accessibleClassRooms(): Classroom[] {
    if (this.isAdmin) {
      return [];
    }
    const isServicePrivider = this.isServicePrivider ?? false;
    const schoolDocId = this.school?.ref.id;
    if (!schoolDocId) {
      return [];
    }
    const classrooms = getClassRoomsBasedOnRole(
      isServicePrivider,
      this.classrooms,
      schoolDocId!
    );
    return classrooms;
  }

  get selectedTutor(): TutorMeta | null {
    if (!this.selectedId) {
      return null;
    }
    const matchTutors = this.tutors.filter(
      tutor => tutor.main.ref.id === this.selectedId
    );
    if (matchTutors.length === 0) {
      return null;
    }

    return matchTutors[0];
  }

  get selectedTutorEmail() {
    return (
      this.tutorUsers.find(user => user.uid === this.selectedId)?.email ?? ""
    );
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    if (this.searchKeyword.length === 0) {
      return this.datas;
    }

    return this.datas.filter(data => {
      return (
        (Object.keys(data).includes("name") &&
          data.name
            .toString()
            .toLowerCase()
            .includes(this.searchKeyword.toLowerCase())) ||
        (Object.keys(data).includes("suf-name") &&
          data["suf-name"]
            .toString()
            .toLowerCase()
            .includes(this.searchKeyword.toLowerCase()))
      );
    });
  }

  showAddModal() {
    this.isDisplayedAddTutorModal = true;
  }

  closeAddModal() {
    this.isDisplayedAddTutorModal = false;
  }

  showEditModal() {
    if (!this.selectedTutor || !this.tutor) {
      return;
    }
    if (this.selectedTutor.config.data.authority === "owner") {
      alert("オーナー権限を持つスタッフを編集することはできません");
      return;
    } else if (this.selectedTutor.config.data.authority === "admin") {
      if (this.tutor.config.data.authority !== "owner") {
        alert("管理者権限を持つスタッフを編集することはできません");
        return;
      }
    } else if (this.selectedTutor.config.data.authority === "supervisor") {
      if (!["owner", "admin"].includes(this.tutor.config.data.authority)) {
        alert("責任者権限を持つスタッフを編集することはできません");
        return;
      }
    } else if (this.tutor.config.data.authority === "general") {
      alert("スタッフを編集する権限を持っていません");
      return;
    }
    this.isDisplayedEditTutorModal = true;
  }

  closeEditModal() {
    this.isDisplayedEditTutorModal = false;
  }

  changeSelectedId(id: string) {
    this.selectedId = id;
    this.showEditModal();
  }

  created() {
    this.updateDatas();
  }

  async updateDatas() {
    if (this.tutors.length === 0) {
      return;
    }

    this.datas = this.tutors.map(tutor => {
      return convertToTutorTableData(tutor, this.accessibleClassRooms);
    });

    this.tutorUsers = await getAllLoginTutors();
  }
}
